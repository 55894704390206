import autobind from 'common/decorators/autobind.js';
import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';

import AutoCompleteForm from 'core/components/AutoCompleteForm.js';
import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';
import Question, {
    limitAnswers,
    genericProps,
} from 'questions/components/Question.js';
import capitalise from 'utils/capitalise.js';
import Icon from 'utils/components/Icon.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

const messages = defineMessages({
    defaultAdditionalText: {
        id: 'questions.CheckboxQuestion.defaultAdditionalText',
        defaultMessage: 'defaultAdditionalText',
    },
    searchNoAnswerText: {
        id: 'questions.CheckboxQuestion.searchNoAnswerText',
        defaultMessage: 'searchNoAnswerText',
    },
    noAnswersSearchButton: {
        id: 'questions.CheckboxQuestion.noAnswersSearchButton',
        defaultMessage: 'noAnswersSearchButton',
    },
});

export default class CheckboxQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        additionalAnswerText: PropTypes.string,
        answers: PropTypes.array.isRequired,
        capitalisation: PropTypes.oneOf(['title', 'sentence']),
        disabledResultText: PropTypes.string,
        disabledResults: PropTypes.array,
        noAnswerText: PropTypes.string,
        required: PropTypes.bool,
        searchAction: PropTypes.func,
        searchPlaceholder: PropTypes.string,
        submitAction: PropTypes.func.isRequired,
        undoAction: PropTypes.func.isRequired,
    };

    static defaultProps = {
        capitalisation: 'sentence',
        disabledResults: [],
        required: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            extraAnswers: [],
            shouldPinSubmitButton: false,
            showAutocomplete: false,
            selectedAnswers: [],
        };
    }

    componentDidUpdate(prevProps) {
        const {active, completed} = this.props;
        if (!completed && !active && prevProps.active) {
            this.setState({selectedAnswers: []});
        }
    }

    getAnswers() {
        const answers = limitAnswers(this.props.answers) || [];
        return answers
            .filter(
                (answer) =>
                    !this.state.extraAnswers.some(
                        (item) => item.id === answer.id,
                    ),
            )
            .concat(this.state.extraAnswers);
    }

    shouldAlwaysShowAutocomplete() {
        return !this.props.answers.length && this.canSearchAnswers();
    }

    canSearchAnswers() {
        return typeof this.props.searchAction === 'function';
    }

    canSubmit() {
        if (this.props.required) {
            return !!this.state.selectedAnswers.length;
        } else {
            return true;
        }
    }

    @autobind
    async reloadAction() {
        const data = await this.props.reloadAction();
        if (data && data.answers) {
            this.setState({
                selectedAnswers: this.props.answers.filter((answer) =>
                    data.answers.some((selected) => answer.id === selected.id),
                ),
                extraAnswers: this.props.answers.filter((answer) =>
                    data.answers.some((selected) => answer.id === selected.id),
                ),
            });
        }
    }

    @autobind
    async submitAction() {
        await this.props.submitAction({answers: this.state.selectedAnswers});
    }

    @autobind
    async undoAction() {
        await this.props.undoAction({answers: this.state.selectedAnswers});
    }

    @autobind
    handleAnswerSelectedChange(event) {
        const elem = event.currentTarget;
        const answer = this.getAnswers()[parseInt(elem.value)];
        if (elem.checked) {
            // sort answers in order they were presented, rather than the
            // order they were selected
            const newAnswers = [...this.state.selectedAnswers, answer];
            newAnswers.sort((a, b) => {
                const indexA = this.getAnswers().findIndex(
                    (item) => item.id === a.id,
                );
                const indexB = this.getAnswers().findIndex(
                    (item) => item.id === b.id,
                );
                return indexA - indexB;
            });
            this.setState({
                selectedAnswers: newAnswers,
            });
        } else {
            const filtered = this.state.selectedAnswers.filter(
                (selectedAnswer) => selectedAnswer.id !== answer.id,
            );
            this.setState({selectedAnswers: filtered});
        }
    }

    renderAnswer(answer, i) {
        const isChecked = this.state.selectedAnswers.some(
            (selectedAnswer) => selectedAnswer.id === answer.id,
        );
        let answerText = capitalise(answer.text, this.props.capitalisation);
        const disabled = this.props.disabledResults.some(
            (item) => item.id === answer.id,
        );
        if (disabled && this.props.disabledResultText) {
            answerText = `${answerText} ${this.props.disabledResultText}`;
        }
        return (
            <label
                aria-disabled={disabled}
                data-test-id="checkbox-option"
                key={i}
            >
                <input
                    checked={isChecked}
                    disabled={disabled}
                    onChange={this.handleAnswerSelectedChange}
                    type="checkbox"
                    value={i}
                />
                <Icon name="IconCheckbox" />
                <span className="answer">{answerText}</span>
            </label>
        );
    }

    @autobind
    handleEnter() {
        if (
            !this.state.selectedAnswers.length ||
            this.state.showAutocomplete
        ) {
            return;
        }
        this.submitAction();
    }

    @autobind
    toggleAutoCompleteView() {
        this.setState({showAutocomplete: !this.state.showAutocomplete});
    }

    @autobind
    handleAutoCompleteSubmit({answer}) {
        if (answer) {
            const newExtraAnswers = [...this.state.extraAnswers];
            // find answer in existing answers
            const existingIndex = this.state.extraAnswers.findIndex(
                (ans) => answer.id === ans.id,
            );
            if (existingIndex > -1) {
                // move it to the end if it's already in the list
                newExtraAnswers.splice(existingIndex, 1);
            }
            newExtraAnswers.push(answer);
            this.setState({
                extraAnswers: newExtraAnswers,
                selectedAnswers: [...this.state.selectedAnswers, answer],
            });
        }
        this.setState({
            showAutocomplete: false,
        });
    }

    @autobind
    handleUpdateButtonPinnedState(shouldPinSubmitButton) {
        if (shouldPinSubmitButton !== this.state.shouldPinSubmitButton) {
            this.setState({shouldPinSubmitButton});
        }
    }

    renderAutoCompleteForm() {
        const intl = this.props.intl;
        const resultsToDisable = this.state.selectedAnswers.map((item) => ({
            id: item.id,
        }));
        // hide the 'Cancel search' button if autocomplete is always showed
        const noAnswerText = this.shouldAlwaysShowAutocomplete()
            ? undefined
            : intl.formatMessage(messages.searchNoAnswerText);
        return (
            <AutoCompleteForm
                capitalisation={this.props.capitalisation}
                disabledResultText={this.props.disabledResultText}
                disabledResults={resultsToDisable}
                hideAutoComplete={this.toggleAutoCompleteView}
                noAnswerText={noAnswerText}
                placeholder={this.props.searchPlaceholder}
                resetAfterSubmission={this.shouldAlwaysShowAutocomplete()}
                searchAction={this.props.searchAction}
                submitAction={this.handleAutoCompleteSubmit}
            />
        );
    }

    renderNoAnswerText() {
        if (this.props.noAnswerText) {
            return this.props.noAnswerText;
        } else if (this.canSearchAnswers()) {
            // none or not sure
            return this.props.intl.formatMessage(
                messages.noAnswersSearchButton,
            );
        } else {
            // none of the above
            return this.props.intl.formatMessage({
                id: 'questions.Question.defaultNoAnswerText',
            });
        }
    }

    renderUndoText() {
        let undoAnswerText = '';
        if (this.state.selectedAnswers.length) {
            // show actual selected answers
            undoAnswerText = this.state.selectedAnswers
                .map((answer) => answer.text)
                .join(', ');
        } else {
            undoAnswerText = this.renderNoAnswerText();
        }
        return undoAnswerText;
    }

    renderCheckboxAnswers() {
        const intl = this.props.intl;
        const answers = this.getAnswers();
        let buttonText = null;
        if (!this.props.required && !this.state.selectedAnswers.length) {
            buttonText = this.renderNoAnswerText();
        }
        return (
            <React.Fragment>
                {answers.length > 0 && (
                    <nav className="options" data-test-id="checkbox-options">
                        {answers.map((answer, i) =>
                            this.renderAnswer(answer, i),
                        )}
                        {this.canSearchAnswers() &&
                            !this.shouldAlwaysShowAutocomplete() && (
                                <label
                                    className="question-toggle"
                                    data-test-id="autocomplete-view-toggle"
                                >
                                    <button
                                        onClick={this.toggleAutoCompleteView}
                                    >
                                        {this.props.additionalAnswerText
                                            ? this.props.additionalAnswerText
                                            : intl.formatMessage(
                                                  messages.defaultAdditionalText,
                                              )}
                                    </button>
                                </label>
                            )}
                    </nav>
                )}
                {this.shouldAlwaysShowAutocomplete() &&
                    this.renderAutoCompleteForm()}
                <AnswerSubmitButton
                    additionalClass={
                        this.state.shouldPinSubmitButton ? 'pinned' : null
                    }
                    dataTestId={
                        this.state.selectedAnswers.length
                            ? 'continue-button'
                            : 'no-answer-button'
                    }
                    buttonText={buttonText}
                    isDisabled={!this.canSubmit()}
                    isSecondary={!this.state.selectedAnswers.length}
                    submitAnswers={this.submitAction}
                />
            </React.Fragment>
        );
    }

    renderForm() {
        if (
            this.canSearchAnswers() &&
            !this.shouldAlwaysShowAutocomplete() &&
            this.state.showAutocomplete
        ) {
            return this.renderAutoCompleteForm();
        }
        return this.renderCheckboxAnswers();
    }

    render() {
        return (
            <Question
                {...genericProps(this.props)}
                handleEnter={this.handleEnter}
                reloadAction={this.reloadAction}
                undoAction={this.undoAction}
                undoText={this.renderUndoText()}
                updateButtonPinnedState={this.handleUpdateButtonPinnedState}
            >
                {this.renderForm()}
            </Question>
        );
    }
}
