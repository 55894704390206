import React from 'react';

import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';
import GroupingQuestion from 'questions/components/GroupingQuestion.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';
import {types} from 'questions/helpers.js';

export default class ChoiceQuestion extends React.Component {
    render() {
        const {questionType, ...props} = this.props;
        if (questionType === types.RADIO) {
            return <RadioQuestion {...props} />;
        } else if (questionType === types.GROUPING) {
            return <GroupingQuestion {...props} />;
        }
        return <CheckboxQuestion {...props} />;
    }
}
