import React from 'react';
import PropTypes from 'prop-types';

import Question, {genericProps} from 'questions/components/Question.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

export default class CheckpointQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        submitAction: PropTypes.func.isRequired,
        undoAction: PropTypes.func.isRequired,
    };

    componentDidUpdate(prevProps) {
        if (this.props.active && !prevProps.active) {
            this.props.submitAction();
        }
    }

    render() {
        return (
            <Question
                {...genericProps(this.props)}
                additionalClass={'checkpoint'}
                disableScrollIntoView={true}
                reloadAction={this.props.reloadAction}
                undoAction={this.props.undoAction}
            ></Question>
        );
    }
}
