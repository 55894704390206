import autobind from 'common/decorators/autobind.js';
import React from 'react';
import {defineMessages} from 'react-intl';
import PropTypes from 'prop-types';

import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';
import Question, {genericProps} from 'questions/components/Question.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

const messages = defineMessages({
    noAnswer: {
        id: 'questions.TextQuestion.noAnswer',
        defaultMessage: 'noAnswer',
    },
});

export default class TextQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        autoFocus: PropTypes.bool,
        required: PropTypes.bool,
        rows: PropTypes.string,
        shouldReload: PropTypes.bool.isRequired,
        showLargeTextField: PropTypes.bool,
        submitAction: PropTypes.func.isRequired,
        undoAction: PropTypes.func.isRequired,
    };

    static defaultProps = {
        autoFocus: false,
        required: true,
        rows: '3',
        showLargeTextField: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            answer: null,
        };
    }

    isButtonDisabled() {
        return this.props.required && !this.state.answer;
    }

    onSubmit(event) {
        event.preventDefault();
    }

    @autobind
    async submitAction() {
        const {answer} = this.state;
        await this.props.submitAction({answer});
    }

    @autobind
    async undoAction() {
        const {answer} = this.state;
        await this.props.undoAction({answer});
    }

    @autobind
    async reloadAction() {
        const data = await this.props.reloadAction();
        this.setState(data);
    }

    @autobind
    handleAnswerChange(event) {
        event.preventDefault();
        this.setState({answer: event.currentTarget.value});
    }

    @autobind
    handleEnter() {
        if (!this.isButtonDisabled()) {
            this.submitAction();
        }
    }

    render() {
        const undoText =
            this.state.answer ||
            this.props.intl.formatMessage(messages.noAnswer);
        return (
            <Question
                {...genericProps(this.props)}
                handleEnter={this.handleEnter}
                reloadAction={this.reloadAction}
                undoAction={this.undoAction}
                undoText={undoText}
            >
                <form onSubmit={this.onSubmit}>
                    {this.props.showLargeTextField ? (
                        <textarea
                            autoFocus={this.props.autoFocus}
                            onChange={this.handleAnswerChange}
                            placeholder={this.props.placeholder}
                            rows={this.props.rows}
                            value={this.state.answer || ''}
                        />
                    ) : (
                        <input
                            autoFocus={this.props.autoFocus}
                            data-test-id="text-input"
                            onChange={this.handleAnswerChange}
                            placeholder={this.props.placeholder}
                            type="text"
                            value={this.state.answer || ''}
                        />
                    )}
                </form>
                <AnswerSubmitButton
                    dataTestId="continue-button"
                    isDisabled={this.isButtonDisabled()}
                    submitAnswers={this.submitAction}
                />
            </Question>
        );
    }
}
