import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';

import autobind from 'common/decorators/autobind.js';
import AutoCompleteForm from 'core/components/AutoCompleteForm.js';
import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';
import Question, {
    limitAnswers,
    genericProps,
} from 'questions/components/Question.js';
import capitalise from 'utils/capitalise.js';
import Icon from 'utils/components/Icon.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

export const messages = defineMessages({
    defaultAdditionalText: {
        id: 'questions.RadioQuestion.defaultAdditionalText',
        defaultMessage: 'defaultAdditionalText',
    },
    searchNoAnswerText: {
        id: 'questions.RadioQuestion.searchNoAnswerText',
        defaultMessage: 'searchNoAnswerText',
    },
    noAnswersSearchButton: {
        id: 'questions.RadioQuestion.noAnswersSearchButton',
        defaultMessage: 'noAnswersSearchButton',
    },
});

export default class RadioQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        answers: PropTypes.array,
        capitalisation: PropTypes.oneOf(['title', 'sentence']),
        disabledAnswers: PropTypes.array,
        hasNoAnswerOption: PropTypes.bool,
        noAnswerOptionText: PropTypes.string,
        rangeMinimumText: PropTypes.string,
        rangeType: PropTypes.bool,
        searchAction: PropTypes.func,
        submitAction: PropTypes.func.isRequired,
        undoAction: PropTypes.func.isRequired,
    };

    static defaultProps = {
        capitalisation: 'sentence',
        disabledAnswers: [],
        rangeType: false,
        hasNoAnswerOption: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            answer: null,
            showAutocomplete: false,
        };
        this.isMouseEvent = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.completed && !this.props.completed) {
            this.setState({answer: null});
        }
    }

    getAnswers() {
        const {answers} = this.props;
        return answers
            ? limitAnswers(answers)
            : [...Array(11).keys()].map((answer) => {
                  let answerText = String(answer);
                  if (answer === 0 && this.props.rangeMinimumText) {
                      answerText = `0 - ${this.props.rangeMinimumText}`;
                  }
                  return {
                      id: answer,
                      text: answerText,
                  };
              });
    }

    @autobind
    handleEnter() {
        if (this.state.answer) {
            this.submitAction(this.state.answer);
        }
    }

    getAnswer(event) {
        const {currentTarget} = event;
        return this.getAnswers()[parseInt(currentTarget.value)];
    }

    @autobind
    handleAnswerChange(event) {
        const answer = this.getAnswer(event);
        this.setState({answer});
        if (this.isMouseEvent) {
            this.submitAction(answer);
        }
    }

    @autobind
    handleInputClick(event) {
        const answer = this.getAnswer(event);
        if (this.isMouseEvent && answer === this.state.answer) {
            this.submitAction(answer);
        }
    }

    @autobind
    handleEventType({type}) {
        this.isMouseEvent = type === 'mousedown';
    }

    @autobind
    async handleNoAnswer() {
        const answer = {id: null, text: this.renderNoAnswerText()};
        this.setState({answer});
        await this.submitAction(answer);
    }

    @autobind
    async reloadAction() {
        const data = await this.props.reloadAction();
        if (data?.answer) {
            if (data.answer.id === null) {
                data.answer.text = this.renderNoAnswerText();
            } else {
                const answers = this.getAnswers();
                data.answer = answers.find(
                    (answer) => answer.id === data.answer.id,
                );
            }
            this.setState(data);
        }
    }

    async submitAction(answer) {
        await this.props.submitAction({answer});
    }

    @autobind
    async undoAction() {
        const {answer} = this.state;
        await this.props.undoAction({answer});
    }

    @autobind
    renderAnswerButton(answer) {
        return (
            <button
                className="cta"
                key={answer.id}
                onBlur={() => {
                    if (this.state.answer === answer) {
                        this.setState({answer: null});
                    }
                }}
                onClick={() => {
                    this.setState({answer}, this.handleEnter);
                }}
                onFocus={() => this.setState({answer})}
                type="button"
            >
                {answer.text}
            </button>
        );
    }

    shouldRenderAutoCompleteToggle() {
        return this.canSearchAnswers() && !this.shouldAlwaysShowAutocomplete();
    }

    renderAutoCompleteToggle() {
        if (this.shouldRenderAutoCompleteToggle()) {
            return (
                <label
                    className="question-toggle"
                    data-test-id="autocomplete-view-toggle"
                >
                    <button onClick={this.toggleAutoCompleteView}>
                        {this.props.additionalAnswerText
                            ? this.props.additionalAnswerText
                            : this.props.intl.formatMessage(
                                  messages.defaultAdditionalText,
                              )}
                    </button>
                </label>
            );
        }
        return null;
    }

    renderAnswers(answers, classlist) {
        if (!answers.length) {
            return null;
        } else if (
            answers.length <= 2 &&
            !this.shouldRenderAutoCompleteToggle()
        ) {
            return (
                <div
                    className="cta-binary"
                    data-test-id="radio-button-options"
                >
                    {answers.map(this.renderAnswerButton)}
                </div>
            );
        } else {
            return (
                <nav
                    className={classlist}
                    data-test-id={
                        this.props.rangeType
                            ? 'radio-range-options'
                            : 'radio-normal-options'
                    }
                >
                    {answers.map((answer, i) =>
                        this.renderAnswer(answer, i, answers),
                    )}
                    {this.renderAutoCompleteToggle()}
                </nav>
            );
        }
    }

    renderAnswer(answer, i, answers) {
        const checked =
            answer.id === (this.state.answer && this.state.answer.id);
        const isDisabled = this.props.disabledAnswers.indexOf(answer.id) > -1;
        const useRadioIcon =
            !this.props.rangeType &&
            (answers.length > 2 || this.props.hasNoAnswerOption);
        return (
            <label
                aria-disabled={isDisabled}
                key={i}
                onKeyDown={this.handleEventType}
                onMouseDown={this.handleEventType}
            >
                <input
                    checked={checked}
                    disabled={isDisabled}
                    name="radio-group"
                    onChange={this.handleAnswerChange}
                    onClick={this.handleInputClick}
                    type="radio"
                    value={i}
                />
                {useRadioIcon && <Icon name="IconRadio" />}
                {this.props.rangeType &&
                this.props.rangeMinimumText &&
                answer.id === 0 ? (
                    <span className="answer">
                        {answer.id}
                        <span>{` - ${this.props.rangeMinimumText}`}</span>
                    </span>
                ) : (
                    <span className="answer">
                        {capitalise(answer.text, this.props.capitalisation)}
                    </span>
                )}
            </label>
        );
    }

    canSearchAnswers() {
        return typeof this.props.searchAction === 'function';
    }

    shouldAlwaysShowAutocomplete() {
        return !this.props.answers?.length && this.canSearchAnswers();
    }

    @autobind
    toggleAutoCompleteView() {
        this.setState({showAutocomplete: !this.state.showAutocomplete});
    }

    renderRadioAnswers() {
        const answers = this.getAnswers();
        let classlist = 'options';
        if (this.props.rangeType) {
            classlist += ' options-number';
        }
        const buttonText = this.renderNoAnswerText();
        return (
            <>
                {this.renderAnswers(answers, classlist)}
                {this.shouldAlwaysShowAutocomplete() &&
                    this.renderAutoCompleteForm()}
                {this.props.hasNoAnswerOption && (
                    <AnswerSubmitButton
                        buttonText={buttonText}
                        dataTestId="no-answer-button"
                        isSecondary={true}
                        submitAnswers={this.handleNoAnswer}
                    />
                )}
            </>
        );
    }

    @autobind
    handleAutoCompleteSubmit({answer}) {
        if (answer) {
            this.setState({answer});
            this.submitAction(answer);
        }
        this.setState({
            showAutocomplete: false,
        });
    }

    renderAutoCompleteForm() {
        // hide the 'Cancel search' button if autocomplete is always shown
        const noAnswerText = this.shouldAlwaysShowAutocomplete()
            ? ''
            : this.props.intl.formatMessage(messages.searchNoAnswerText);
        return (
            <AutoCompleteForm
                capitalisation={this.props.capitalisation}
                hideAutoComplete={this.toggleAutoCompleteView}
                noAnswerText={noAnswerText}
                placeholder={this.props.searchPlaceholder}
                resetAfterSubmission={this.shouldAlwaysShowAutocomplete()}
                searchAction={this.props.searchAction}
                submitAction={this.handleAutoCompleteSubmit}
            />
        );
    }

    renderForm() {
        if (
            this.canSearchAnswers() &&
            !this.shouldAlwaysShowAutocomplete() &&
            this.state.showAutocomplete
        ) {
            return this.renderAutoCompleteForm();
        }
        return this.renderRadioAnswers();
    }

    renderNoAnswerText() {
        if (this.props.noAnswerOptionText) {
            return this.props.noAnswerOptionText;
        } else if (this.canSearchAnswers()) {
            // none or not sure
            return this.props.intl.formatMessage(
                messages.noAnswersSearchButton,
            );
        } else {
            // none of the above
            return this.props.intl.formatMessage({
                id: 'questions.Question.defaultNoAnswerText',
            });
        }
    }

    render() {
        const questionProps = {
            reloadAction: this.reloadAction,
            ...genericProps(this.props),
        };

        return (
            <Question
                {...questionProps}
                handleEnter={this.handleEnter}
                undoAction={this.undoAction}
                undoText={this.state.answer?.text || this.renderNoAnswerText()}
            >
                {this.renderForm()}
            </Question>
        );
    }
}
