import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages} from 'react-intl';

import autobind from 'common/decorators/autobind.js';
import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';
import Question, {genericProps} from 'questions/components/Question.js';
import {YES, NO} from 'questions/helpers.js';
import Icon from 'utils/components/Icon.js';

const messages = defineMessages({
    continue: {
        id: 'questions.GroupingQuestion.continue',
        defaultMessage: 'continue',
    },
    no: {
        id: 'questions.GroupingQuestion.no',
        defaultMessage: 'no',
    },
    yes: {
        id: 'questions.GroupingQuestion.yes',
        defaultMessage: 'yes',
    },
});

export default class GroupingQuestion extends React.Component {
    static propTypes = {
        active: PropTypes.bool.isRequired,
        answers: PropTypes.array,
        completed: PropTypes.bool.isRequired,
        helper: PropTypes.object,
        helperOpenByDefault: PropTypes.bool,
        loading: PropTypes.bool.isRequired,
        subTitle: PropTypes.string,
        subTitleCapitalisation: PropTypes.oneOf(['title', 'sentence']),
        submitAction: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        undo: PropTypes.bool.isRequired,
        undoAction: PropTypes.func.isRequired,
        undoable: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedAnswers: [],
            answers: [],
        };
    }

    componentDidMount() {
        const {answers} = this.props;
        this.setState({answers: answers.map(({id, text}) => ({id, text}))});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.completed && !this.props.completed) {
            this.setState({selectedAnswers: []});
        }
    }

    @autobind
    handleEnter() {
        if (this.state.selectedAnswers.length !== this.state.answers.length) {
            return;
        }
        this.submitAction(this.state.selectedAnswers);
    }

    @autobind
    handleAnswerChange(event) {
        const {currentTarget} = event;
        const answer = this.state.answers[currentTarget.id];
        const selected = {
            id: answer.id,
            value: currentTarget.value,
            text: answer.text,
        };
        const filtered = this.state.selectedAnswers.filter(
            (selectedAnswer) => selectedAnswer.id !== answer.id,
        );
        this.setState({selectedAnswers: [...filtered, selected]});
    }

    @autobind
    handleSubmit(event) {
        event.preventDefault();
        const {selectedAnswers} = this.state;
        this.submitAction(selectedAnswers);
    }

    @autobind
    async reloadAction() {
        const data = await this.props.reloadAction();
        if (data?.answers) {
            this.setState({selectedAnswers: data.answers});
        }
    }

    async submitAction(selectedAnswers) {
        await this.props.submitAction({answers: selectedAnswers});
    }

    @autobind
    async undoAction() {
        const {selectedAnswers} = this.state;
        await this.props.undoAction({answers: selectedAnswers});
    }

    renderOptions(i) {
        return (
            <>
                <label data-test-id="grouping-option">
                    <input
                        id={i}
                        name={`radio-group-${i}`}
                        onChange={this.handleAnswerChange}
                        type="radio"
                        value={YES}
                    />
                    <Icon name="IconRadio" />
                    <span>{this.props.intl.formatMessage(messages.yes)}</span>
                </label>
                <label data-test-id="grouping-option">
                    <input
                        id={i}
                        name={`radio-group-${i}`}
                        onChange={this.handleAnswerChange}
                        type="radio"
                        value={NO}
                    />
                    <Icon name="IconRadio" />
                    <span>{this.props.intl.formatMessage(messages.no)}</span>
                </label>
            </>
        );
    }

    renderAnswer(answer, i) {
        return (
            <div className="grouping" data-test-id="grouping" key={i}>
                <span data-test-id="grouping-question">
                    {answer.text.concat('?')}
                </span>
                {this.renderOptions(i)}
            </div>
        );
    }

    renderUndoText() {
        return this.state.selectedAnswers
            .map((answer) => {
                const val =
                    answer.value === YES
                        ? this.props.intl.formatMessage(messages.yes)
                        : this.props.intl.formatMessage(messages.no);
                return `${answer.text}: ${val}`;
            })
            .join(', ');
    }

    render() {
        return (
            <Question
                {...genericProps(this.props)}
                handleEnter={this.handleEnter}
                reloadAction={this.reloadAction}
                undoAction={this.undoAction}
                undoText={this.renderUndoText()}
            >
                <nav className="options">
                    {this.state.answers.map((answer, i) =>
                        this.renderAnswer(answer, i),
                    )}
                </nav>

                <AnswerSubmitButton
                    buttonText={this.props.intl.formatMessage(
                        messages.continue,
                    )}
                    dataTestId="continue-button"
                    // eslint-disable-next-line max-len
                    isDisabled={
                        this.state.selectedAnswers.length !==
                        this.state.answers.length
                    }
                    isSecondary={false}
                    submitAnswers={this.handleSubmit}
                />
            </Question>
        );
    }
}
