import PropTypes from 'prop-types';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import AutoCompleteForm from 'core/components/AutoCompleteForm.js';
import Question, {genericProps} from 'questions/components/Question.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

export default class AutoCompleteQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        capitalisation: PropTypes.oneOf(['title', 'sentence']),
        disabledResultText: PropTypes.string,
        disabledResults: PropTypes.array,
        enableFreeText: PropTypes.bool,
        noAnswerText: PropTypes.string,
        placeholder: PropTypes.string.isRequired,
        reloadAction: PropTypes.func.isRequired,
        searchAction: PropTypes.func.isRequired,
        searchData: PropTypes.object,
        shouldReload: PropTypes.bool.isRequired,
        submitAction: PropTypes.func.isRequired,
        undoAction: PropTypes.func.isRequired,
    };

    static defaultProps = {
        capitalisation: 'sentence',
        disabledResults: [],
        enableFreeText: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            answer: null,
            searchResults: [],
            searchValue: '',
        };
    }

    componentDidUpdate(prevProps) {
        const {active, completed} = this.props;
        if (!completed && !active && prevProps.active) {
            this.setState({
                answer: null,
                searchResults: [],
                searchValue: '',
            });
        }
    }

    @autobind
    async reloadAction() {
        const data = await this.props.reloadAction();
        this.setState(data);
    }

    @autobind
    async submitAction({answer, searchResults, searchValue}) {
        // return when answer text is empty and answer is not 'None or not sure'
        if (answer !== null && !answer.text) {
            return;
        }
        this.setState({
            answer,
            searchResults,
            searchValue,
        });
        await this.props.submitAction({
            answer,
            searchResults,
            searchValue,
        });
    }

    @autobind
    async undoAction() {
        const {answer, searchResults, searchValue} = this.state;
        await this.props.undoAction({
            answer,
            searchResults,
            searchValue,
        });
    }

    render() {
        const undoText = this.state.answer
            ? this.state.answer.text
            : this.props.noAnswerText || '';
        return (
            <Question
                {...genericProps(this.props)}
                reloadAction={this.reloadAction}
                undoAction={this.undoAction}
                undoText={undoText}
            >
                <AutoCompleteForm
                    capitalisation={this.props.capitalisation}
                    disabledResultText={this.props.disabledResultText}
                    disabledResults={this.props.disabledResults}
                    enableFreeText={this.props.enableFreeText}
                    initialSearchResults={this.state.searchResults}
                    initialSearchValue={this.state.searchValue}
                    noAnswerText={this.props.noAnswerText}
                    placeholder={this.props.placeholder}
                    searchAction={this.props.searchAction}
                    searchData={this.props.searchData}
                    submitAction={this.submitAction}
                />
            </Question>
        );
    }
}
