import autobind from 'common/decorators/autobind.js';
import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';

import InputNotice from 'core/components/InputNotice.js';
import NumberInput from 'core/components/NumberInput.js';
import SelectInput from 'core/components/SelectInput.js';
import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';
import Question, {genericProps} from 'questions/components/Question.js';
import {MAX_POSITIVE_INT} from 'utils/constants.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

const messages = defineMessages({
    days: {
        id: 'questions.NumberSelectQuestion.days',
        defaultMessage: 'days',
    },
    defaultValue: {
        id: 'questions.NumberSelectQuestion.defaultValue',
        defaultMessage: 'defaultValue',
    },
    DTFD: {
        id: 'questions.NumberSelectQuestion.days',
        defaultMessage: 'days',
    },
    DTFH: {
        id: 'questions.NumberSelectQuestion.hours',
        defaultMessage: 'hours',
    },
    DTFMI: {
        id: 'questions.NumberSelectQuestion.mins',
        defaultMessage: 'mins',
    },
    DTFMO: {
        id: 'questions.NumberSelectQuestion.months',
        defaultMessage: 'months',
    },
    DTFSE: {
        id: 'questions.NumberSelectQuestion.seconds',
        defaultMessage: 'seconds',
    },
    DTFW: {
        id: 'questions.NumberSelectQuestion.weeks',
        defaultMessage: 'weeks',
    },
    DTFY: {
        id: 'questions.NumberSelectQuestion.years',
        defaultMessage: 'years',
    },
    formatError: {
        id: 'questions.NumberSelectQuestion.formatError',
        defaultMessage: 'formatError',
    },
    hours: {
        id: 'questions.NumberSelectQuestion.hours',
        defaultMessage: 'hours',
    },
    months: {
        id: 'questions.NumberSelectQuestion.months',
        defaultMessage: 'months',
    },
    rangeError: {
        id: 'questions.NumberSelectQuestion.rangeError',
        defaultMessage: 'rangeError',
    },
    TFPDAY: {
        id: 'questions.NumberSelectQuestion.perDay',
        defaultMessage: 'perDay',
    },
    TFPHOUR: {
        id: 'questions.NumberSelectQuestion.perHour',
        defaultMessage: 'perHour',
    },
    TFPMONTH: {
        id: 'questions.NumberSelectQuestion.perMonth',
        defaultMessage: 'perMonth',
    },
    TFPWEEK: {
        id: 'questions.NumberSelectQuestion.perWeek',
        defaultMessage: 'perWeek',
    },
    TFPYEAR: {
        id: 'questions.NumberSelectQuestion.perYear',
        defaultMessage: 'perYear',
    },
    weeks: {
        id: 'questions.NumberSelectQuestion.weeks',
        defaultMessage: 'weeks',
    },
    years: {
        id: 'questions.NumberSelectQuestion.years',
        defaultMessage: 'years',
    },
});

export default class NumberSelectQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        decimalDigits: PropTypes.number,
        defaultAnswer: PropTypes.string,
        defaultOption: PropTypes.string,
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
        options: PropTypes.array,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        submitAction: PropTypes.func.isRequired,
        trailingText: PropTypes.string,
        undoAction: PropTypes.func.isRequired,
    };

    static defaultProps = {
        decimalDigits: 0,
        maxValue: MAX_POSITIVE_INT,
        minValue: 0,
        placeholder: '',
        required: true,
        trailingText: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            firstBlur: true,
            answer: {
                num: '',
                option: this.props.defaultOption || '',
            },
        };

        this.integerTester = /^\d+$/;
        // decimalDigits = 1:
        //   decimalTester.exec('12.3456') = ['12.3456', '12', '.3', '456']
        //   decimalTester.exec('12') = ['12', '12', undefined, undefined]
        this.decimalTester = new RegExp(
            `(\\d+)(?:(\\.\\d{0,${props.decimalDigits}})(\\d*))?$`,
        );
    }

    @autobind
    async reloadAction() {
        const data = await this.props.reloadAction();
        this.setState({
            ...data,
            firstBlur: false,
        });
    }

    getSanitisedNumber(num) {
        let sanitisedNum = num;
        if (this.props.decimalDigits) {
            sanitisedNum = num.replace(/[^\d.]/g, '');
            const result = this.decimalTester.exec(sanitisedNum);
            if (result) {
                const integerPart = result[1];
                const fractionalPart = result[2] || '';
                sanitisedNum = `${integerPart}${fractionalPart}`;
            }
        } else {
            sanitisedNum = num.replace(/[^\d]/g, '');
        }
        return sanitisedNum;
    }

    componentDidUpdate(prevProps) {
        const {active, completed} = this.props;
        if (!completed && !active && prevProps.active) {
            this.setState({
                answer: {
                    num: '',
                    option: this.props.defaultOption || '',
                },
            });
        }
    }

    @autobind
    async submitAction() {
        const {answer} = this.state;
        if (this.props.defaultAnswer && !this.isCompleted()) {
            // used to signify answered with default option
            answer.num = -1;
        }
        await this.props.submitAction({answer});
    }

    @autobind
    async undoAction() {
        const {answer} = this.state;
        await this.props.undoAction({answer});
    }

    @autobind
    handleNumberChange(event) {
        const num = this.getSanitisedNumber(event.currentTarget.value);
        this.setState({
            answer: {
                ...this.state.answer,
                num,
            },
        });
    }

    getOptions() {
        return this.props.options
            ? this.getPluralisedOptions(this.state.answer.num)
            : null;
    }

    @autobind
    handleKeyUp() {
        // Workaround for number typed input.
        // onChange may not be fired if value is invalid to the browser.
        // E.g. typing in "?" on FireFox does not trigger onChange event.
        if (!this.state.firstBlur) {
            this.validateNumberInput();
        }
    }

    @autobind
    handleNumberPaste(event) {
        // sanitise paste before putting it into the input field.
        event.preventDefault();
        const num = this.getSanitisedNumber(
            event.clipboardData.getData('Text'),
        );
        document.execCommand('insertHTML', false, num);
    }

    checkNumberFormat(value) {
        if (this.props.decimalDigits) {
            const result = this.decimalTester.exec(value);
            return result && !result[3]; // no excessive decimal digits
        }
        return this.integerTester.test(value);
    }

    isNumberValid(value) {
        if (this.checkNumberFormat(value)) {
            value = parseFloat(value);
            if (value >= this.props.minValue && value <= this.props.maxValue) {
                return true;
            }
        }
        return false;
    }

    validateNumberInput() {
        const input = this.numberInput;
        if (!input.value && !input.checkValidity()) {
            this.setState({
                errors: [this.props.intl.formatMessage(messages.formatError)],
            });
            return;
        } else if (input.value) {
            if (this.checkNumberFormat(input.value)) {
                const value = parseFloat(input.value);
                const minVal = this.props.minValue;
                const maxVal = this.props.maxValue;
                if (value < minVal) {
                    this.setState({
                        errors: [
                            this.props.intl.formatMessage(
                                messages.rangeError,
                                {type: 'min', val: minVal},
                            ),
                        ],
                    });
                    return;
                } else if (value > maxVal) {
                    this.setState({
                        errors: [
                            this.props.intl.formatMessage(
                                messages.rangeError,
                                {type: 'max', val: maxVal},
                            ),
                        ],
                    });
                    return;
                }
            } else {
                this.setState({
                    errors: [
                        this.props.intl.formatMessage(messages.formatError),
                    ],
                });
                return;
            }
        }
        this.setState({errors: []});
    }

    @autobind
    handleBlur() {
        this.setState({firstBlur: false});
        this.validateNumberInput();
    }

    @autobind
    handleSelectChange(value) {
        this.setState({
            answer: {
                ...this.state.answer,
                option: value,
            },
        });
    }

    getPluralisedOptions(numCount = 0) {
        return this.props.options.map((item) => ({
            id: item.id,
            text: this.props.intl
                .formatMessage(messages[item.id], {
                    count: numCount,
                    trailingText: this.props.trailingText,
                })
                .trim(),
        }));
    }

    @autobind
    handleEnter() {
        if (this.canSubmit()) {
            this.submitAction();
        } else {
            // handle blur is used to trigger error message events
            this.handleBlur();
        }
    }

    isCompleted() {
        // completed if valid number set and no options or option set
        return (
            (!this.getOptions() || this.state.answer.option) &&
            this.isNumberValid(this.state.answer.num)
        );
    }

    canSubmit() {
        return this.isCompleted() || !this.props.required;
    }

    renderErrors(errors, errorId) {
        return errors.map((error, index) => (
            <InputNotice errorId={errorId} key={index} message={error} />
        ));
    }

    getUndoText() {
        if (!this.canSubmit()) {
            return '';
        }
        let optionText = '';
        if (this.getOptions() && this.state.answer.option) {
            optionText = this.getOptions().find(
                (obj) => obj.id === this.state.answer.option,
            ).text;
        }
        let undoText = `${this.state.answer.num} ${optionText}`;
        if (!this.isCompleted() && this.props.defaultAnswer) {
            undoText = this.props.defaultAnswer;
        }
        return undoText.trim();
    }

    render() {
        const intl = this.props.intl;
        const defaultButtonText = this.isCompleted()
            ? null
            : this.props.defaultAnswer;
        return (
            <Question
                {...genericProps(this.props)}
                handleEnter={this.handleEnter}
                reloadAction={this.reloadAction}
                undoAction={this.undoAction}
                undoText={this.getUndoText()}
            >
                <form className="stack">
                    <label data-test-id="number-label">
                        <NumberInput
                            ariaInvalid={this.state.errors.length > 0}
                            decimalDigits={this.props.decimalDigits}
                            errorDescribedBy="num-error"
                            handleBlur={this.handleBlur}
                            handleChange={this.handleNumberChange}
                            handleKeyUp={this.handleKeyUp}
                            handlePaste={this.handleNumberPaste}
                            maxValue={this.props.maxValue}
                            minValue={this.props.minValue}
                            placeholder={this.props.placeholder}
                            ref={(node) => {
                                this.numberInput = node;
                            }}
                            value={this.state.answer.num}
                        />
                        {this.state.errors &&
                            this.renderErrors(this.state.errors, 'num-error')}
                    </label>
                    {this.getOptions() && (
                        <SelectInput
                            defaultOptionValue={intl.formatMessage(
                                messages.defaultValue,
                            )}
                            handleChange={this.handleSelectChange}
                            initialValue={
                                this.state.answer.option ||
                                this.props.defaultOption
                            }
                            options={this.getOptions()}
                        />
                    )}
                </form>
                <AnswerSubmitButton
                    buttonText={defaultButtonText}
                    dataTestId={
                        !this.props.required && !this.isCompleted()
                            ? 'no-answer-button'
                            : 'continue-button'
                    }
                    isDisabled={!this.canSubmit()}
                    isSecondary={!this.props.required && !this.isCompleted()}
                    submitAnswers={this.submitAction}
                />
            </Question>
        );
    }
}
