import autobind from 'common/decorators/autobind.js';
import moment from 'moment';
import React from 'react';
import {defineMessages} from 'react-intl';
import PropTypes from 'prop-types';

import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';
import Question, {genericProps} from 'questions/components/Question.js';
import Icon from 'utils/components/Icon.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';
import events from 'events.js';

const messages = defineMessages({
    pickADay: {
        id: 'questions.DateQuestion.pickADay',
        defaultMessage: 'pickADay',
    },
    pickAMonth: {
        id: 'questions.DateQuestion.pickAMonth',
        defaultMessage: 'pickAMonth',
    },
    pickAYear: {
        id: 'questions.DateQuestion.pickAYear',
        defaultMessage: 'pickAYear',
    },
});

export default class DateQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        autoFocus: PropTypes.bool,
        dateOrder: PropTypes.string,
        max: PropTypes.string,
        min: PropTypes.string,
        reloadAction: PropTypes.func.isRequired,
        required: PropTypes.bool,
        submitAction: PropTypes.func.isRequired,
        undoAction: PropTypes.func.isRequired,
    };

    static defaultProps = {
        autoFocus: false,
        dateOrder: 'DMY',
        required: true,
        max: moment().format('YYYY-MM-DD'),
        min: moment().subtract(120, 'years').format('YYYY-MM-DD'),
    };

    constructor(props) {
        super(props);

        this.dayRef = null;
        this.monthRef = null;
        this.yearRef = null;
        this.questionRef = null;

        this.handleDayChange = this.handleChange('day');
        this.handleMonthChange = this.handleChange('month');
        this.handleYearChange = this.handleChange('year');

        this.handleDayFocus = this.handleFocus('day');
        this.handleMonthFocus = this.handleFocus('month');
        this.handleYearFocus = this.handleFocus('year');

        this.handleDayMouseDown = this.handleMouseDown('day');
        this.handleMonthMouseDown = this.handleMouseDown('month');
        this.handleYearMouseDown = this.handleMouseDown('year');

        this.handleDayBackspace = this.handleBackspace('day');
        this.handleMonthBackspace = this.handleBackspace('month');
        this.handleYearBackspace = this.handleBackspace('year');

        this.state = {
            answers: {
                day: this.initDays(),
                month: this.initMonths(),
                year: this.initYears(),
            },
            complete: false,
            currentUnit: null,
            inputValues: {
                day: '',
                month: '',
                year: '',
            },
            selected: {
                day: {value: null},
                month: {value: null},
                year: {value: null},
            },
        };

        this.blockingConditions = this.initBlockingConditions();
    }

    componentDidMount() {
        events.listen(window, 'mousedown', this.handleMouseDown());
    }

    componentWillUnmount() {
        events.unlisten(window, 'mousedown', this.handleMouseDown());
    }

    componentDidUpdate(prevProps) {
        const {active, completed} = this.props;
        if (!completed && !active && prevProps.active) {
            this.setState({
                complete: false,
                currentUnit: null,
                inputValues: {
                    day: '',
                    month: '',
                    year: '',
                },
                selected: {
                    day: {value: null},
                    month: {value: null},
                    year: {value: null},
                },
            });
        }
    }

    padWithZero(num) {
        return num.toString().padStart(2, '0');
    }

    initDays() {
        return [...Array(31).keys()].map((n) => {
            const dayNum = n + 1;
            return {
                displayText: dayNum,
                synonyms: [dayNum.toString(), this.padWithZero(dayNum)],
                value: dayNum.toString(),
            };
        });
    }

    initMonths() {
        const longMonths = moment.months();
        const shortMonths = moment.monthsShort();
        return shortMonths.map((m, index) => {
            const monthNum = index + 1;
            return {
                displayText: m,
                synonyms: [
                    m.toLowerCase(),
                    longMonths[index].toLowerCase(),
                    this.padWithZero(monthNum),
                    monthNum.toString(),
                ],
                value: monthNum.toString(),
            };
        });
    }

    initYears() {
        const {min, max} = this.props;
        const minYear = moment(min).year();
        const maxYear = moment(max).year();
        const years = [];
        for (
            let currentYear = maxYear;
            currentYear >= minYear;
            currentYear--
        ) {
            years.push({
                displayText: currentYear,
                inputDisplayText: currentYear,
                synonyms: [currentYear.toString()],
                value: currentYear.toString(),
                leap: moment([currentYear]).isLeapYear(),
            });
        }
        return years;
    }

    getNextUnit(unit, direction = 1) {
        const unitMap = {
            D: 'day',
            M: 'month',
            Y: 'year',
        };
        const sequence = this.props.dateOrder
            .split('')
            .map((unit) => unitMap[unit]);
        const unitIndex = sequence.indexOf(unit);
        return sequence[unitIndex + direction];
    }

    getInputRef(unit, direction = 1) {
        const nextUnit = this.getNextUnit(unit, direction);
        if (nextUnit && !this.state.selected[nextUnit].value) {
            return this[`${nextUnit}Ref`];
        }
        return null;
    }

    blankAnswer() {
        const {selected} = this.state;
        return (
            !selected.day.value &&
            !selected.month.value &&
            !selected.year.value
        );
    }

    formatCompleteAnswer() {
        const values = [
            this.state.selected.year.value,
            this.state.selected.month.value,
            this.state.selected.day.value,
        ];
        return moment(values, 'YYYY-M-D').format('YYYY-MM-DD');
    }

    checkCompleteAnswer() {
        const {selected} = this.state;
        return (
            !!selected.day.value &&
            !!selected.month.value &&
            !!selected.year.value
        );
    }

    @autobind
    setCompleteAnswer() {
        const complete = this.checkCompleteAnswer();
        if (this.state.complete !== complete) {
            this.setState({complete});
        }
    }

    handleFocus(currentUnit) {
        return () => {
            this.setState({
                complete: false,
                currentUnit,
            });
        };
    }

    shouldJumpFocus(unit, value) {
        const completeDayNumRegex = /^(0[1-9]|[4-9]|[1-2][0-9]|3[0-1])$/;
        const completeMonthNumRegex = /^(0[1-9]|[2-9]|1[0-2])$/;

        return (
            (unit === 'day' && completeDayNumRegex.test(value)) ||
            (unit === 'month' && completeMonthNumRegex.test(value)) ||
            (unit === 'month' && this.isCompleteMonthName(value))
        );
    }

    isCompleteMonthName(value) {
        return (
            moment
                .months()
                .some(
                    (longMonth) =>
                        longMonth.toLowerCase() === value.toLowerCase(),
                ) ||
            moment
                .monthsShort()
                .some(
                    (shortMonth) =>
                        shortMonth.toLowerCase() === value.toLowerCase(),
                )
        );
    }

    handleChange(unit) {
        return (event) => {
            const newInputValue = event.target.value;

            if (newInputValue === '') {
                this.setInputValue(unit, newInputValue);
                this.setSelectedAnswer(unit, {value: null});
                return;
            }

            let allowedValue = false;
            if (unit === 'day') {
                allowedValue = /^(0|0?[1-9]|[1-2][0-9]|3[0-1])$/.test(
                    newInputValue,
                );
            } else if (unit === 'year') {
                allowedValue = /^[1-9](\d?){3}$/.test(newInputValue);
            } else {
                if (/\d/.test(newInputValue)) {
                    allowedValue = /^(0|0?[1-9]|1[0-2])$/.test(newInputValue);
                } else {
                    // allow any non-numerical string for month names
                    allowedValue = /^((?!\d).)*$/.test(newInputValue);
                }
            }
            if (allowedValue) {
                this.setInputValue(unit, newInputValue);
            }

            const newAnswer = this.getValidAnswer(unit, newInputValue);
            this.setSelectedAnswer(unit, newAnswer);
        };
    }

    handleBackspace(unit) {
        return (event) => {
            if (event.key === 'Backspace' && event.target.value === '') {
                const previousUnit = this.getNextUnit(unit, -1);
                if (previousUnit) {
                    this[`${previousUnit}Ref`].focus();
                }
            }
        };
    }

    handleMouseDown(unit = null) {
        return (event) => {
            if (this.props.active) {
                if (unit) {
                    // handle inside inputs
                    event.stopPropagation();
                } else {
                    // handle outside inputs
                    if (!this.questionRef.contains(event.target)) {
                        return;
                    }

                    if (this.state.currentUnit) {
                        this.dayRef.blur();
                        this.monthRef.blur();
                        this.yearRef.blur();
                    } else if (this.blankAnswer()) {
                        event.preventDefault();
                        const firstInput = {
                            DMY: this.dayRef,
                            MDY: this.monthRef,
                        };
                        firstInput[this.props.dateOrder].focus();
                    }
                }
            }
        };
    }

    getCurrentDay() {
        return moment().date();
    }

    getCurrentMonthIndex() {
        return moment().month();
    }

    blockFutureMonths(state, futureMonth) {
        const currentYear = state.answers.year[0].value;
        const currentMonth = this.getCurrentMonthIndex() + 1;
        const currentDay = this.getCurrentDay();

        if (state.selected.year.value === currentYear) {
            return parseInt(state.selected.day.value) > currentDay
                ? parseInt(futureMonth) >= currentMonth
                : parseInt(futureMonth) > currentMonth;
        }
        return false;
    }

    blockFutureDays(state, futureDay) {
        const currentYear = state.answers.year[0].value;
        const currentMonth = this.getCurrentMonthIndex() + 1;

        return (
            state.selected.year.value === currentYear &&
            parseInt(state.selected.month.value) === currentMonth &&
            parseInt(futureDay) > this.getCurrentDay()
        );
    }

    @autobind
    blockFutureYear(state) {
        const currentDay = this.getCurrentDay();
        const currentMonth = this.getCurrentMonthIndex() + 1;

        return (
            (parseInt(state.selected.day.value) > currentDay &&
                parseInt(state.selected.month.value) >= currentMonth) ||
            parseInt(state.selected.month.value) > currentMonth
        );
    }

    blockNonLeapYear(state) {
        return (
            state.selected.day.value === '29' &&
            state.selected.month.value === '2'
        );
    }

    initBlockingConditions() {
        const years = this.state.answers.year;
        const nonLeapYears = years.filter((year) => !year.leap);
        const currentYear = years[0];

        const day = {
            ...[...Array(28).keys()].reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr + 1]: (state) =>
                        this.blockFutureDays(state, (curr + 1).toString()),
                }),
                {},
            ),
            '29': (state) =>
                this.blockFutureDays(state, '29') ||
                (state.selected.month.value === '2' &&
                    state.selected.year.value &&
                    !state.selected.year.leap),
            '30': (state) =>
                this.blockFutureDays(state, '30') ||
                state.selected.month.value === '2',
            '31': (state) =>
                this.blockFutureDays(state, '31') ||
                ['2', '4', '6', '9', '11'].includes(
                    state.selected.month.value,
                ),
        };

        const month = {
            '2': (state) =>
                this.blockFutureMonths(state, '2') ||
                state.selected.day.value === '30' ||
                state.selected.day.value === '31' ||
                (state.selected.day.value === '29' &&
                    state.selected.year?.leap === false),
            ...['1', '3', '5', '7', '8', '10', '12'].reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr]: (state) => this.blockFutureMonths(state, curr),
                }),
                {},
            ),
            ...['4', '6', '9', '11'].reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr]: (state) =>
                        this.blockFutureMonths(state, curr) ||
                        state.selected.day.value === '31',
                }),
                {},
            ),
        };

        const year = {
            ...nonLeapYears.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.value]: this.blockNonLeapYear,
                }),
                {},
            ),
            [currentYear.value]: (state) => {
                return (
                    this.blockFutureYear(state) ||
                    (!currentYear.leap && this.blockNonLeapYear(state))
                );
            },
        };

        return {day, month, year};
    }

    getValidAnswer(unit, newValue) {
        const noAnswer = {value: null};
        const validAnswer = this.state.answers[unit].find((answer) =>
            answer.synonyms.includes(newValue.toLowerCase()),
        );
        return validAnswer || noAnswer;
    }

    setSelectedAnswer(unit, answer) {
        let newAnswer = {value: null};
        if (!this.isBlockedAnswer(unit, answer.value)) {
            newAnswer = answer;
        }
        this.setState(
            {
                selected: {
                    ...this.state.selected,
                    [unit]: newAnswer,
                },
            },
            this.setCompleteAnswer,
        );
    }

    setInputValue(unit, value, selectedViaOption = false) {
        this.setState(
            {
                inputValues: {
                    ...this.state.inputValues,
                    [unit]: value,
                },
            },
            () => {
                if (this.shouldJumpFocus(unit, value) || selectedViaOption) {
                    const nextInput = this.getInputRef(unit);
                    if (nextInput) {
                        nextInput.focus();
                    }
                }
                this.setCompleteAnswer();
            },
        );
    }

    renderLegendText(unit) {
        const legendTexts = {
            day: messages.pickADay,
            month: messages.pickAMonth,
            year: messages.pickAYear,
        };
        return this.props.intl.formatMessage(legendTexts[unit]);
    }

    selectOption(unit, answer) {
        this.setState(
            {
                selected: {
                    ...this.state.selected,
                    [unit]: answer,
                },
            },
            () => {
                this.setInputValue(
                    unit,
                    answer.displayText.toString(),
                    unit !== 'year',
                );
            },
        );
    }

    @autobind
    async reloadAction() {
        const data = await this.props.reloadAction();
        if (!data) {
            return;
        }

        const answerDate = moment(data.answer, 'YYYY-MM-DD');
        const answerDay = answerDate.date().toString();
        const answerMonth = (answerDate.month() + 1).toString();
        const answerYear = answerDate.year().toString();

        const day = this.state.answers.day.find(
            (day) => day.value === answerDay,
        );
        const month = this.state.answers.month.find(
            (month) => month.value === answerMonth,
        );
        const year = this.state.answers.year.find(
            (year) => year.value === answerYear,
        );

        this.setState({
            complete: true,
            inputValues: {
                day: answerDay,
                month: answerMonth,
                year: answerYear,
            },
            selected: {
                day,
                month,
                year,
            },
        });
    }

    @autobind
    submitAction() {
        if (this.checkCompleteAnswer()) {
            const answer = this.formatCompleteAnswer();
            this.props.submitAction({answer});
        } else if (!this.props.required) {
            this.props.submitAction({answer: null});
        }
    }

    @autobind
    undoAction() {
        const answer = this.formatCompleteAnswer();
        this.props.undoAction({answer});
    }

    renderUndoText() {
        if (!this.checkCompleteAnswer()) {
            return '';
        }
        const formats = {
            DMY: 'DD/MM/YYYY',
            MDY: 'MM/DD/YYYY',
        };
        return moment(this.formatCompleteAnswer(), 'YYYY-MM-DD').format(
            formats[this.props.dateOrder],
        );
    }

    isBlockedAnswer(unit, value) {
        const blockingCondition = this.blockingConditions[unit][value];
        return blockingCondition ? blockingCondition(this.state) : false;
    }

    renderAnswers(unit, answer) {
        const {value, displayText} = answer;
        return (
            <li
                aria-current={this.state.selected[unit].value === value}
                aria-disabled={this.isBlockedAnswer(unit, value)}
                data-test-id={`${unit}-${value}`}
                data-value={value}
                key={value}
                onClick={() => this.selectOption(unit, answer)}
            >
                {displayText}
            </li>
        );
    }

    @autobind
    renderUnitPicker(unit) {
        const answers = this.state.answers[unit];
        const capitalUnit = unit.charAt(0).toUpperCase() + unit.slice(1);
        return (
            <fieldset
                aria-current={this.state.currentUnit === unit}
                aria-hidden={this.state.complete}
                className={`${unit}-picker`}
                data-test-id={`${unit}-picker`}
                key={unit}
            >
                <Icon name="IconDateSplitter" />
                <input
                    data-test-id={`${unit}-picker-input`}
                    inputMode="decimal"
                    name={unit}
                    onChange={this[`handle${capitalUnit}Change`]}
                    onFocus={this[`handle${capitalUnit}Focus`]}
                    onKeyDown={this[`handle${capitalUnit}Backspace`]}
                    onMouseDown={this[`handle${capitalUnit}MouseDown`]}
                    onTouchEnd={this[`handle${capitalUnit}MouseDown`]}
                    placeholder={unit}
                    ref={(node) => {
                        this[`${unit}Ref`] = node;
                    }}
                    type="text"
                    value={this.state.inputValues[unit]}
                />
                <legend>{this.renderLegendText(unit)}</legend>
                <ol>
                    {answers.map((answer) => this.renderAnswers(unit, answer))}
                </ol>
            </fieldset>
        );
    }

    renderDatePicker() {
        const order = {
            DMY: ['day', 'month', 'year'],
            MDY: ['month', 'day', 'year'],
        };
        return order[this.props.dateOrder].map(this.renderUnitPicker);
    }

    render() {
        return (
            <Question
                {...genericProps(this.props)}
                handleEnter={this.submitAction}
                reloadAction={this.reloadAction}
                setQuestionRef={(node) => {
                    this.questionRef = node;
                }}
                undoAction={this.undoAction}
                undoText={this.renderUndoText()}
            >
                <form className="date-picker">{this.renderDatePicker()}</form>
                <AnswerSubmitButton
                    dataTestId="continue-button"
                    isDisabled={!this.state.complete}
                    submitAnswers={this.submitAction}
                />
            </Question>
        );
    }
}
