import autobind from 'common/decorators/autobind.js';
import React from 'react';
import PropTypes from 'prop-types';

import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';
import Question, {genericProps} from 'questions/components/Question.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

export default class FormQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        answers: PropTypes.array,
        autoFocus: PropTypes.bool,
        maxLength: PropTypes.number,
        submitAction: PropTypes.func.isRequired,
        undoAction: PropTypes.func.isRequired,
    };

    static defaultProps = {
        autoFocus: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            answers: props.answers,
        };
    }

    @autobind
    async reloadAction() {
        const data = await this.props.reloadAction();
        this.setState(data);
    }

    @autobind
    async submitAction() {
        const {answers} = this.state;
        await this.props.submitAction({answers});
    }

    @autobind
    async undoAction() {
        const {answers} = this.state;
        await this.props.undoAction({answers});
    }

    @autobind
    handleInputChange(event) {
        event.preventDefault();
        const name = event.target.name;
        const {answers} = this.state;
        answers[parseInt(name)] = event.target.value;
        this.setState({answers});
    }

    renderInput(input, index) {
        const autoFocus = index === 0 && this.props.autoFocus;
        const value = this.state.answers[index] || '';
        return (
            <label data-test-id="text-label" key={index}>
                {input.label}
                <input
                    autoFocus={autoFocus}
                    data-test-id="text-input"
                    maxLength={
                        this.props.maxLength ? this.props.maxLength : ''
                    }
                    name={index}
                    onChange={this.handleInputChange}
                    placeholder={input.placeholder}
                    type="text"
                    value={value}
                />
            </label>
        );
    }

    onSubmit(event) {
        event.preventDefault();
    }

    @autobind
    handleEnter() {
        if (!this.isButtonDisabled()) {
            this.submitAction();
        }
    }

    isButtonDisabled() {
        for (const answers of this.state.answers) {
            if (!answers) {
                return true;
            }
        }
        return false;
    }

    render() {
        const undoText = this.state.answers.join(' ').trim();
        return (
            <Question
                {...genericProps(this.props)}
                handleEnter={this.handleEnter}
                reloadAction={this.reloadAction}
                undoAction={this.undoAction}
                undoText={undoText}
            >
                <form onSubmit={this.onSubmit}>
                    {this.props.inputs.map((input, index) =>
                        this.renderInput(input, index),
                    )}
                </form>
                <AnswerSubmitButton
                    dataTestId="continue-button"
                    isDisabled={this.isButtonDisabled()}
                    submitAnswers={this.submitAction}
                />
            </Question>
        );
    }
}
